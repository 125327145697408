$logo-size: 72px;
$radius: 50px;

@import url("https://cdn.barques.co.uk/sites/barques/assets/fonts/fonts-web.min.css?ver=5.0.3");

body.login {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    width: 100%;
    font-family: SuisseIntl, Helvetica Neue, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    background-color: #edf5f8;
    background: radial-gradient(circle at 0 100%, rgba(0, 155, 223, .99), rgb(19, 40, 76));

    #login {
        padding: 0 10px;
        box-shadow: none;
        width: 100%;
        max-width: 360px;

        .message,
        .success,
        #login_error {
            position: relative;
            border-radius: $radius;
            padding-left: 42px;
            font-weight: 500;
            color: #5e6669;
            border: 0;

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 16px;
                width: 12px;
                height: 12px;
                display: block;
                border-radius: 100%;
                transform: translateY(-50%);
            }
        }

        .message {
            &::before {
                background-color: #258fd7;
                box-shadow: 0 0px 6px 0 rgba(37, 143, 215, 0.85);
            }
        }

        .success {
            &::before {
                background-color: #57D66C;
                box-shadow: 0 0px 6px 0 rgba(87, 214, 108, 0.85);
            }
        }

        #login_error {
            &::before {
                background-color: #F9353D;
                box-shadow: 0 0px 6px 0 rgba(249, 53, 61, 0.85);
            }
        }

        #loginform {
            background-color: transparent;
            box-shadow: none;
            overflow: visible;
            padding: 24px 0;
            border: 0;

            label {
                color: #edf5f8;
                text-decoration: none;
                font-size: 13px;
                line-height: 1.75;
                font-weight: 600;

                &[for=user_pass],
                &[for=user_login] {
                    margin-left: 16px;
                }
            }

            #user_login,
            #user_pass {
                font-size: 16px;
                line-height: 2.4;
                border: 0;
                width: 100%;
                background-color: #ffffff;
                color: #464646;
                border-radius: $radius;
                padding: 0 16px;
                box-shadow: 0 0 0 0 #57D66C;
                transition: all 0.25s ease-out;

                &:focus {
                    outline: none;
                    box-shadow: 0 0 0 2px #57D66C;
                }
            }

            .button.wp-hide-pw {
                border-radius: $radius;
                outline: none;
                box-shadow: none;
                color: #258fd7;
                margin-right: 7px;

                &:focus {
                    outline: none;
                    box-shadow: none;
                }
            }

            input[type=checkbox] {
                position: relative;
                background-color: transparent;
                appearance: none;
                width: 24px;
                height: 24px;
                border: 0;
                margin: -0.25rem 0.5rem 0 0;
                border-radius: 100%;
                overflow: hidden;
                box-shadow: 0 0 0 1px white;

                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    margin: 0;
                    color: white;
                    outline-color: white !important;
                    background-color: transparent;
                    width: 100%;
                    height: 100%;
                    transition: all 0.25s ease-out;
                }

                &::after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    border-right: 2px solid transparent;
                    border-bottom: 2px solid transparent;
                    transform-origin: center;
                }

                &:checked {
                    &::before {
                        background-color: white;
                    }

                    &::after {
                        animation: checkbox-check 0.25s ease-out forwards;
                    }
                }

                &:hover,
                &:focus {
                    outline: none !important;
                }

                &:hover {
                    &::before {
                        background-color: rgba(white, 0.25);
                    }

                    &:checked {
                        &::before {
                            background-color: white;
                        }
                    }
                }
            }

            .forgetmenot {
                display: flex;
                align-items: center;
                justify-content: center;
                user-select: none;
                margin-top: 6px;
                margin-left: 8px;
            }
        }

        h1 {
            a {
                background-image: url("https://cdn.barques.co.uk/sites/barques/assets/images/logo/barques_logo_white.svg");
                background-size: $logo-size !important;
                height: $logo-size !important;
                width: $logo-size !important;
                outline: 0;
                border: 0;
                box-shadow: none;
                margin: 0 auto 48px;
            }
        }

        #nav {
            margin-top: 48px;
        }

        #backtoblog,
        #nav {
            padding: 0;
        }

        #backtoblog,
        #nav,
        h1 {
            a {
                color: #edf5f8;
                font-size: 14px;

                &:hover {
                    color: #edf5f8;
                    text-decoration: underline;
                }
            }
        }

        input[type=text],
        input[type=password],
        input[type=checkbox],
        input[type=color],
        input[type=date],
        input[type=datetime],
        input[type=datetime-local],
        input[type=email],
        input[type=month],
        input[type=number],
        input[type=radio],
        input[type=tel],
        input[type=time],
        input[type=url],
        input[type=week],
        input[type=search],
        select,
        textarea,
        input[type=checkbox]:checked:before {
            outline-color: #43B3A2 !important;
        }

        .button:not(.wp-hide-pw):not(#wp-submit) {
            -webkit-box-shadow: none !important;
            box-shadow: none !important;
            -webkit-border-radius: 0 !important;
            -moz-border-radius: 0 !important;
            border-radius: 0 !important;
            color: white !important;
            text-shadow: none;

            &:hover {
                background-color: #EDEDED !important;
                border-color: #EDEDED !important;
                color: #464646 !important;
            }
        }

        #wp-submit {
            background-color: white;
            color: #258fd7;
            font-weight: 500;
            border-radius: $radius;
            padding: 2px 16px;
            border: 0;
            box-shadow: 0 2px 6px -2px rgba(0, 0, 0, 0.2);
        }
    }
}

@keyframes checkbox-check {
    0% {
        width: 0;
        height: 0;
        border-color: #57D66C;
        transform: translate3d(-50%, -50%, 0) rotate(45deg);
    }

    33% {
        width: .2em;
        height: 0;
        transform: translate3d(-50%, -50%, 0) rotate(45deg);
    }

    100% {
        width: .35em;
        height: .85em;
        border-color: #57D66C;
        transform-origin: center;
        transform: translate3d(-50%, -50%, 0) rotate(45deg);
    }
}